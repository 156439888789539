export const extractUserData = (formData = {}) => {
  if (!formData?.name || formData?.name?.trim() === "") {
    return { email: formData?.email, name: "RAV User" };
  }
  return formData;
};

export const fetchOptions = {
  method: "POST",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};
