import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import Base from "../Base";
import Sidebar from "components/widgets/sidebar";
import ContentWidgetsSection from "../../components/widgets/content/Index";
import Space from "../../components/common/Space";

export default function SidebarLayout({ children, ...props }) {
  return (
    <Base {...props}>
      <div id="page-header" />
      <ContentWidgetsSection widgets={props?.widgets?.["top-full-width"]} />
      <Container fluid="xl" className="pt-3 pt-md-4">
        <Row>
          <ContentWidgetsSection widgets={props?.widgets?.["top"]} />
        </Row>
        <Row>
          <Col as="main" md="12" lg="8">
            <ContentWidgetsSection widgets={props?.widgets?.["content-top"]} />
            {children}
            <Space height="2rem" />
            <ContentWidgetsSection
              widgets={props?.widgets?.["content-bottom"]}
            />
          </Col>
          <Col as="aside" md="12" lg="4">
            <Sidebar items={props?.widgets?.sidebar || []} />
          </Col>
        </Row>
        <ContentWidgetsSection widgets={props?.widgets?.["bottom"]} />
      </Container>
      <ContentWidgetsSection widgets={props?.widgets?.["bottom-full-width"]} />
    </Base>
  );
}
