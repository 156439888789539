import React from "react";
import styles from "./styles.module.scss";
import Divider from "components/common/titles/Divider";
import OurApps from "../blocks/Applications/OurApps";
import SubscribeForm from "../Subscribe/SubscribeForm";

export default function AppContent() {
  return (
    <div className={styles.wrapper}>
      <h1 className="fw-bold">Download Real America's Voice Apps</h1>
      <Divider />
      <p className="mb-0">
        JUST REAL NEWS & HONEST VIEWS! WATCH ON DISH CH 219
      </p>
      <p className="mb-0">PLUTO TV CH 240 · ROKU · SAMSUNG TV PLUS 1029</p>
      <p className="mb-4">SELECT TV CH 106 · APPLE TV · FIRE TV</p>
      <p className="mb-3">
        Get exclusive content by downloading Real America's Voice Apps on all
        your favorite devices.
      </p>
      <p className="fw-bold">
        Click/Tap on the icons below to begin downloading our apps now.
      </p>
      <div className={styles.appWrapper}>
        <OurApps hiddeView={true} />
      </div>
      <p className="fw-bold mt-2">
        Real America's Voice is where politics become accessible.
      </p>
      <p>
        Americas Voice Network is a Conservative multiplatform news &
        entertainment media organization featuring content focused on
        Conservative values. We lend a voice to the voiceless by passionately
        raising issues and providing a forum for you to carry the conversation
        forward across our website, social media and interactive applications.
      </p>
      <Divider />
      <div className={styles.appWrapper}>
        <SubscribeForm />
      </div>
    </div>
  );
}
