import React, { useEffect, useState, useCallback } from "react";
import { Spinner, Form } from "react-bootstrap";
import Link from "next/link";

import useAuth from "hooks/useAuth";
import ButtonRedBlack from "components/common/buttons/ButtonRedBlack";
import {
  AUTH_API_URL,
  PRIVACY_URL,
  TERMS_URL,
} from "../../../config/constants";
import {
  extractUserData,
  fetchOptions,
} from "../../../helpers/subscribeHelpers";

import styles from "./styles.module.scss";

function SubscribeForm() {
  const { user } = useAuth();
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState("");
  const [fetching, setFetching] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });

  useEffect(() => {
    if (user) {
      setFormData((prev) => ({
        ...prev,
        name: user?.given_name || "",
        email: user?.email || "",
      }));
    }
  }, [user, setFormData]);

  const onFormChange = useCallback(
    (e) => {
      setFormData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    },
    [setFormData]
  );

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity()) {
      setError("");
      setFetching(true);
      fetch(`${AUTH_API_URL}/subscribe`, {
        ...fetchOptions,
        body: JSON.stringify(extractUserData(formData)),
      })
        .then((res) => res.json())
        .then((response) => {
          !response.success && setError(response.message);
        })
        .catch((err) => console.error(err))
        .finally(() => {
          setFetching(false);
          setSubscribed(true);
        });
    }

    setValidated(true);
  };

  return (
    <Form
      noValidate
      className={styles.root}
      validated={validated}
      onSubmit={handleSubmit}
      onChange={onFormChange}
    >
      {subscribed ? (
        <div className="text-center pt-3 h3">Thank you for subscription!</div>
      ) : (
        <React.Fragment>
          <Form.Group controlId="name" className="mb-3">
            <Form.Label>First name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              defaultValue={formData.name}
            />
          </Form.Group>
          <Form.Group controlId="email" className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              required
              type="email"
              name="email"
              defaultValue={formData.email}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid email
            </Form.Control.Feedback>
          </Form.Group>
          <p>
            We keep your data private and share your data only with third
            parties that make this service possible. Read our{" "}
            <Link href={PRIVACY_URL}>
              <a href={PRIVACY_URL} className={styles.link}>
                Privacy
              </a>
            </Link>{" "}
            and{" "}
            <Link href={TERMS_URL}>
              <a href={TERMS_URL} className={styles.link}>
                Terms
              </a>
            </Link>
            .
          </p>
          <ButtonRedBlack
            type="submit"
            className="position-relative me-auto ms-auto"
          >
            <span>
              {fetching && (
                <Spinner
                  animation="border"
                  variant="light"
                  size="sm"
                  className={styles.spinner}
                />
              )}
              <span className={fetching ? styles.fetching : undefined}>
                Subscribe!
              </span>
            </span>
          </ButtonRedBlack>
          <Form.Group className="mt-3">
            <Form.Control type="hidden" isInvalid />
            <Form.Control.Feedback type="invalid">
              {error}
            </Form.Control.Feedback>
          </Form.Group>
        </React.Fragment>
      )}
    </Form>
  );
}

export default SubscribeForm;
