import React from "react";

import SidebarLayout from "../../layouts/SidebarLayout";
import Head from "components/Head";
import AppContent from "components/App";
import { HOST_URL } from "../../config/constants";

export default function AboutPage(props) {
  return (
    <SidebarLayout {...props}>
      <Head>
        <title>Apps - Real America's Voice News</title>
        <meta name="description" content="Download Real America's Voice Apps" />
        <meta property="og:title" content="Real America's Voice Apps" />

        <meta
          name="og:description"
          content="Download Real America's Voice Apps"
        />
        <meta property="og:url" content="https://americasvoice.news/app" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={`${HOST_URL}/app/`} />
      </Head>

      <AppContent />
    </SidebarLayout>
  );
}

export async function getServerSideProps() {
  const apiPrivateUrl = process.env.apiPrivateUrl;

  try {
    const apiEndpoints = [
      `${apiPrivateUrl}/widgets/sidebar`,
      `${apiPrivateUrl}/menu/sidebar`,
      `${apiPrivateUrl}/menu/footer`,
      `${apiPrivateUrl}/menu/header`,
    ];

    const [sidebar, sidebarMenu, footerMenu, headerMenu] = await Promise.all(
      apiEndpoints.map((endpoint) =>
        fetch(endpoint)
          .then((response) => response.json())
          .catch((error) => console.error(error))
      )
    );

    return {
      props: {
        widgets: {
          top: [],
          "content-top": [],
          "content-bottom": [],
          sidebar,
        },
        sidebarMenu,
        footerMenu,
        headerMenu,
        headerType: "homeHeader",
      },
    };
  } catch (e) {
    console.group("pages/app/index.jsx - getServerSideProps");
    console.log(e);
    console.groupEnd();

    return {
      notFound: true,
    };
  }
}
